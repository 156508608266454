<template>
  <div class="invitation">
    <hearder-input
      @getUserList="invitationInput"
      title="请输入客户微信昵称"
    ></hearder-input>
    <template>
      <el-table
        style="width: 100%"
        :header-cell-style="headClass"
        :data="invitationList"
        row-key="id"
        :tree-props="{
          children: 'customerInfoRespList',
        }"
      >
        <el-table-column prop="wxUsername" label="客户微信昵称">
        </el-table-column>
        <el-table-column label="相亲卡ID">
          <template slot-scope="scope">
            <div v-for="item in scope.row.card" :key="item.id">
              {{ item.cardId }}
            </div>
          </template>
        </el-table-column>
        <el-table-column label="是否为VIP">
          <template slot-scope="scope">
            <div>{{ scope.row.vipStatus === 1 ? "是" : "否" }}</div>
          </template>
        </el-table-column>
        <el-table-column prop="createTime" label="注册时间"></el-table-column>
        <el-table-column
          prop="invitationCount"
          label="邀请好友数"
        ></el-table-column>
        <el-table-column label="操作" align="center">
          <template slot-scope="scope">
            <el-button @click="query(scope.row)">查看跟踪与投诉</el-button>
          </template>
        </el-table-column>
      </el-table>
    </template>
    <div class="pagination">
      <pagination
        :total="total"
        :size="queryInfo.size"
        :page="queryInfo.page"
        @selectSizeChange="selectSizeChange"
        @selectPageChange="selectPageChange"
      ></pagination>
    </div>

    <el-dialog
      title="跟踪记录"
      :visible.sync="dialogVisible"
      width="50%"
      :before-close="handleClose"
    >
      <div class="timeList">
        <div class="timeList_left">
          <div class="left_list">
            <div>添加客户</div>
            <div>{{ time }}</div>
          </div>
          <div
            class="left_list2"
            v-for="(item, index) in invitationNotes"
            :key="index"
          >
            <div>第{{ index + 1 }}次跟进记录</div>
            <div>{{ item.createTime }}</div>
          </div>
        </div>
        <el-timeline class="timeList_right">
          <el-timeline-item placement="top" :color="color">
            <div>暂无内容</div>
          </el-timeline-item>

          <el-timeline-item
            placement="top"
            v-for="(item, index) in invitationNotes"
            :key="index"
            :color="color"
          >
            <el-form :model="item">
              <el-input v-model="item.remarkTitle" disabled></el-input>
            </el-form>
          </el-timeline-item>
        </el-timeline>
      </div>

      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="open">新增</el-button>
        <el-button type="primary" @click="dialogVisible = false"
          >确定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { request } from "../../API/request";
import hearderInput from "../../components/hearderInput/hearderInput.vue";
import Pagination from "../../components/pagination/pagination.vue";
export default {
  components: { hearderInput, Pagination },
  data() {
    return {
      total: 0,
      queryInfo: {
        size: 10,
        page: 1,
      },
      // 存放input查询数据
      invitationList: [],
      // isZero: false,
      // ---------邀请操作
      dialogVisible: false,
      time: "",
      invitationNotes: [],
      color: "#409eff",
      wxUserId: "",
      // -------------
    };
  },
  methods: {
    // 修改表头的样式
    headClass() {
      return "background:#fff7f7;";
    },
    invitationInput(inputVal) {
      request({
        url: "/customer/pageInviteUsers",
        method: "GET",
        params: {
          page: this.queryInfo.page,
          size: this.queryInfo.size,
          // 微信客户昵称
          wxUsername: inputVal,
        },
      }).then((res) => {
        console.log(res, "res");
        // this.isZero = res.data.records.isZero;
        this.total = res.data.total;
        this.invitationList = res.data.records;
        // console.log(this.invitationList, "this.invitationList");
        for (let i = 0; i < this.invitationList.length; i++) {
          this.invitationList[i].id += "aa";
        }
      });
    },
    // 分页中每页显示几条所触发的回调
    selectSizeChange(e) {
      this.queryInfo.size = e;
      this.invitationInput();
    },
    // 当前第几页所触发的回调
    selectPageChange(e) {
      this.queryInfo.page = e;
      this.invitationInput();
    },
    query(e) {
      this.dialogVisible = true;
      this.time = e.createTime;
      this.invitationNotes = e.invitationNotes;
      this.wxUserId = e.wxUserId;
      console.log(this.invitationNotes, "this.invitationNotes");
      console.log(e, "eeeeeeeeeeeee");
    },
    open() {
      this.$prompt("请输入记录内容", "新增", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        closeOnClickModal: false,
        // inputPattern:"/[, ]",
        // inputErrorMessage: "记录内容不得为空",
      })
        .then(({ value }) => {
          if (this.wxUserId) {
            this.addInvitationNote(value);
          } else {
            this.$message({
              type: "info",
              message: "该用户无微信ID添加不了信息",
            });
          }
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "新增失败",
          });
        });
    },
    addInvitationNote(e) {
      request({
        url: "/invitationNotes/addInvitationNotes",
        method: "POST",
        data: {
          remarkTitle: e,
          wxUserId: this.wxUserId,
        },
      }).then(res=>{
        if (res.code == 200) {
          this.dialogVisible = false;
           this.invitationInput();
          this.wxUserId = "";
        }
      });
    },
    handleClose() {
      this.dialogVisible = false;
    },
  },
};
</script>

<style lang="less" scoped>
.invitation {
  background-color: #ffffff;
  padding: 24px;
  height: 93%;
  position: relative;
}
.el-table {
  margin-top: 24px;
}
.timeList {
  display: flex;
  .timeList_left {
    flex: -1;
    .left_list {
      margin-bottom: 20%;
    }
    .left_list2 {
      margin-bottom: 30%;
    }
    .timeList_right {
      flex: 1;
      .el-input {
        width: 100%;
      }
    }
  }
  .el-timeline {
    flex: 1;
  }
}
</style>